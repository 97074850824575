




// SidePanel.js

import { Link } from 'react-router-dom';
import '../../../cssFiles/index.css'; // Assuming your CSS file is named Dashboard.css


const SidePanelC = () => {

   
    return (
        <div className="sidebar">
            <Link to="/crdtzdesc">CREDITZ List</Link>
            <Link to="/crdtzpay">Buy CREDITZ</Link>

         
           
            
        </div>
    );
};

export default SidePanelC;