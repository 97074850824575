// src/auth/SigninForm.js

//Uses useAuth() to detect email verification status before allowing login
//Redirects unverified users to the email verification page
//Syncs email verification across all tabs using BroadcastChannel()
//Stores login status in localStorage to prevent redundant Firebase calls
//Handles errors properly and provides user-friendly messages

import React, { useEffect,useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useAuth } from "../../contexts/authContext"; // Import AuthContext
import Modal from '../Modal';
import { SignInSchema } from "../../utils/validationSchemas"; // ✅ Import validation schema
import LoadingSpinner from '../LoadingSpinner';
import '../../cssFiles/index.css';

const SigninForm = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth(); // Get authentication functions

  const [showModal, setShowModal] = useState(false);
 const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

/**
* Function to display modal with a message and navigate after delay.
* @param {string} message - The message to display in the modal.
* @param {string} path - The path to navigate to after the delay.
*/

// Initialize BroadcastChannel for email verification sync
const verificationChannelRef = useRef(null); // ✅ Ensures single instance
/**
 * ✅ Handle sign-in submission
 */
  

const handleSubmit = async (values, { setSubmitting }) => {
const { email, password } = values;
setSubmitting(true); // Prevent multiple clicks

try {
  const user = await signIn(email, password); // ✅ Get user object from authContext.js

  await user.reload(); // ✅ Refresh Firebase user
    const updatedEmailVerified = user.emailVerified; // ✅ Get latest email verification status

    console.log("Latest email verification status:", updatedEmailVerified);

// Store email verification status in localStorage
localStorage.setItem("emailVerified", JSON.stringify(updatedEmailVerified));
if (!updatedEmailVerified) {

        setError("Please verify your email before logging in.");
        setSubmitting(false);
        return;
      }
  


    setSuccessMessage('All set!   You are now being redirected to your dashboard.');
      console.log('Setting modal open');
      setShowModal(true); // Show modal on success
   
  setTimeout(() => {
      console.log('Navigating to emailmsg');
      setShowModal(false); // Hide modal after 4 seconds

      navigate('/dashboardb');

      }, 4000); // Delay of 5000 milliseconds (5 seconds)
    } catch (error) {
      console.error('Error during sign-in:', error);
      setError("Invalid email or password. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };
       
/**
* Toggle password visibility.
*/
const toggleShowPassword = () => {
setShowPassword(!showPassword);
};

/**
   * ✅ Close modal function
   */
const handleCloseModal = () => {
  setShowModal(false);
  setSuccessMessage(false);
};

/**
   * ✅ Listen for email verification updates from other tabs
   */
useEffect(() => {
  if (!verificationChannelRef.current) {
    verificationChannelRef.current = new BroadcastChannel("email_verification_channel");
  }

  const channel = verificationChannelRef.current;

  channel.onmessage = (event) => {
    if (event.data.emailVerified) {
      localStorage.setItem("emailVerified", JSON.stringify(true));
    }
  };

  return () => {
    channel.close();
    verificationChannelRef.current = null;
  };
}, []);// ✅ No dependencies needed (only runs once)


return (
<div className="loginForm form-style">
<h2>Log In to Your Neufluence Account</h2>
{error && <div style={{ color: 'red' }}>{error}</div>}


   <Formik
      initialValues={{ 
        email: '', 
        password: '' }}
        validationSchema={SignInSchema} // ✅ Apply validation schema
      onSubmit={handleSubmit}
      >
      {({ isSubmitting }) => (
      <Form>
      <div>
      <label htmlFor="email">Email Address
      <Field
      type="email"
      name="email"
      id="email"
      autoComplete="email"
      required

      />
      <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
      </label>
      </div>

      <div>
      <label htmlFor="password">Password
      <Field
      type={showPassword ? 'text' : 'password'}
      name="password"
      id="password"
      autoComplete="current-password"
      required
      />
      <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
      </label>
      </div>

      <label>
      <input
      type="checkbox"
      checked={showPassword}
      onChange={toggleShowPassword}
      id="showPassword"
      />
      Show Password
      </label>

      <button type="submit" disabled={isSubmitting}>Sign In</button>

      <div className="forgot-password-link">
        <Link to="/passrequest" className="forgot-password-link">Forgot password?</Link>
      </div>
    </Form>
)}
</Formik>

<Modal show={showModal} onClose={handleCloseModal}>
<div>
<p style={{ fontSize: "clamp(14px, 1.2vw, 18px)" }}>{successMessage}</p>
<LoadingSpinner aria-label="Loading..." />
</div>
</Modal>
</div>
);
};

export default SigninForm;























