const firebaseErrorMessages = (error) => {
    switch (error.code) {
        case 'auth/invalid-action-code':
            return '🚨 The verification link is invalid or expired. Please try again.';
        
        case 'auth/user-disabled':
            return 'Your account has been disabled by an administrator.';
       
        case 'auth/user-not-found':
            return '🚨 No account found with this email. Either sign up or try another email address.';

        case 'auth/email-already-in-use':
            return '🚨 This email is already in use. Try logging in or resetting your password.';

        case 'auth/wrong-password':
            return '🚨 Incorrect password. Try entering your password again.';
        
        case 'auth/invalid-email':
            return '🚨 You have entered an invalid email format. Please enter a valid email.';

        case 'auth/too-many-requests':
            return '🚨 Too many failed login attempts. Try again later.';
        
        case 'auth/network-request-failed':
            return '🚨 Network error. Please check your internet connection.';

        case 'auth/requires-recent-login':
            return '🚨 Please log in again before performing this action.';

        case 'firestore/permission-denied':
            return '🚨 You do not have permission to access this data.';
       
        default:
            return 'An unexpected error occurred. Please try again later.';
    }
};

// ✅ Ensure default export is properly defined
export default firebaseErrorMessages;
