

import React from 'react';
import { Link } from 'react-router-dom';

import '../../../cssFiles/campaignForms.css';


const TestCampaignInfo = () => {


  return (
    <div className="overview-container">
          <div className="overview-box">
          <div className="createhead">
      <h2>Neufluence Test Campaign Details</h2>
      <p style={{ textAlign: 'center'}}>*** This program is designed to help a member from your team to run your campaign.***</p>
      <div className="scrollable-content">
      <section style={{ background: "#f0efef", paddingBlock: "5px"}}>
        <br></br>
      <div>
        <h1>Overview</h1>
        </div>
        <p>
        Our goal with the Test Campaign program is to offer new and growing businesses a partially-managed, 
        low-risk way to explore micro creator partnerships and gauge results with diverse, ready-to-work creators….without breaking the bank.
        </p>
        <p>
        Our system guides you step by step through campaign development and workflow processes, 
        with expert human support available to refine messaging, define goals, and evaluate results.
        </p>
        <p>
          Micro creator content fees start at $50, allowing creators to produce and share engaging content on their social channels at an affordable price.
        </p>
        <br></br>
      </section>
      <br></br>
      <section class="creator-section">

        <h1>Example Creator Content & Categories</h1>
        <br></br>
        <div>
        <p style={{fontSize: 18, fontWeight: 500, fontWeight: 'bold', textAlign: 'center'}}>Content options include:</p>
        </div>
        <ul class="bullet-group">
          <li>Instagram Reels</li>
          <li>TikTok videos</li>
          <li>Resharing existing posts</li>
        </ul>
        <br>
        </br>
        <div>
        <p style={{fontSize: 18, fontWeight: 500, fontWeight: 'bold', textAlign: 'center'}}>Creator categories include:</p>
        </div>
      
        <ul class="bullet-group">
          <li>Lifestyle</li>
          <li>Sports/Fitness</li>
          <li>Parenting</li>
        </ul>
      </section>
      <section>
        <br></br>
        <h1>Support Included with Test Campaigns</h1>
        <p style={{fontSize: 18, fontWeight: '500', textAlign: 'center'}}><em>***Your team's participation and approval is required for each aspect of the campaign's workflow.***</em></p>
        <br></br>
        <p style={{fontSize: 18, fontWeight: 'bold', textAlign: 'center'}}>Platform-guided steps include:</p>
        <ul>
          <li>Developing the campaign description, brief, and request.</li>
          <li>Vetting and delivering creators according to your archetype.</li>
          <li>Managing the creator's content deliverables and posting timelines.</li>
          <li>Contracting and paying creators on your behalf.</li>
          <li>Providing the results of the campaign.</li>
        </ul>
        <p style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center' }}>
  Expert support from our team includes: <br />
  <span style={{ fontSize: 14, fontWeight: 'normal' }}>(includes 1 check-in call)</span>
</p>

        <ul>
          <li>Ensuring clarity in your campaign brief, messaging, and requests</li>
          <li>Providing a check-in call to review progress and address concerns</li>
          <li>Monitoring creators' responsiveness and service quality</li>
          <li>Proactively addressing any issues or questions during the campaign</li>
        </ul>
        
      </section>
      <br></br>
      <section style={{ background: "#f0efef", paddingBlock: "5px"}} >
        <h1>FEES </h1>
       
        <ul>
          <li>Partial test campaign management begins at $499. This excludes the creators' fees.</li>
          <li>Up to 4 creators can participate in the campaign</li>
          <li>Offer applies to our curated creators only (we provide the list).</li>
          <li>Other restrictions may apply.</li>
        </ul>
        <p style={{fontSize: "16", fontWeight: 'bold', color: "#d211d2"}}>
  **For additional support, you can take advantage of our {" "}
  <Link to="/creditz">CREDITZ program</Link>, which offers even more tools to optimize your campaigns.**
</p>
      </section>
     

      </div>

    </div>

    </div>

    </div>
    
  );
};

export default TestCampaignInfo;
