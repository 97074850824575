

import React from "react";
import { useFormikContext } from "formik";
import '../cssFiles/selectors.css';

/**
 * Multi-Select Industry Selector Component
 * - Displays selected options as tags with "X" buttons for removal.
 * - Limits selection to **2 industries**.
 * 
 * @param {string} name - The Formik field name.
 */
const IndustrySelector = ({ name }) => {
  const { setFieldValue, values } = useFormikContext();

  const industryOptions = [
    "Automotive", "Beauty", "Business", "Entertainment", "Fashion", "Finance",
    "Food", "Gaming", "Health", "Home Improvement", "Lifestyle",
    "Other", "Parenting", "Pet", "Religion", "Sports/Fitness",
    "Tech", "Travel", "Wellness"
  ];

  // Handle selection change
  const handleChange = (event) => {
    const selectedValue = event.target.value;

    // Prevent selecting more than 2 industries
    if ((values[name] || []).length >= 2 && !(values[name] || []).includes(selectedValue)) {
      return;
    }

    const updatedSelections = (values[name] || []).includes(selectedValue)
      ? (values[name] || []).filter((item) => item !== selectedValue) // Remove if already selected
      : [...(values[name] || []), selectedValue]; // Add new selection

    setFieldValue(name, updatedSelections);
  };

  // Remove industry when clicking "X"
  const removeSelection = (selectedItem) => {
    setFieldValue(name, (values[name] || []).filter((item) => item !== selectedItem));
  };

  return (
    <div className="industry1-container">
      {/* Selected Tags */}
      {(values[name] || []).length > 0 && (
        <div className="selected-tags">
          {(values[name] || []).map((selectedItem) => (
            <span key={selectedItem} className="industry-tag">
              {selectedItem}
              <button type="button" onClick={() => removeSelection(selectedItem)}>✖</button>
            </span>
          ))}
        </div>
      )}

      {/* Multi-Select Dropdown */}
      <select id={name} name={name} onChange={handleChange}>
        <option value="" disabled>Select industries</option>
        {industryOptions.map((option) => (
          <option key={option} value={option} disabled={(values[name] || []).includes(option)}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default IndustrySelector;
