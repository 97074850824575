import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/authContext"; // Import AuthContext
import { verifyPasswordResetCode, applyActionCode, getIdToken } from 'firebase/auth';
import LoadingSpinner from '../LoadingSpinner';
import { auth } from '../../firebase';



const EmailisVerifiedMsg = () => {

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { setIsEmailVerified, user, userData } = useAuth(); // ✅ Import user and setIsEmailVerified

    // ✅ Extract properties to avoid ESLint warnings
    const firstName = userData?.firstName || "";
    const lastName = userData?.lastName || "";
    const companyName = userData?.companyName || "";
    const userType = userData?.userType || "unknown";
   // ✅ Use `useRef` to persist BroadcastChannel
  const verificationChannel = useRef(null);

  useEffect(() => {

        // ✅ Initialize `BroadcastChannel` only once
        verificationChannel.current = new BroadcastChannel("email_verification_channel");


  const queryParams = new URLSearchParams(window.location.search);

    const mode = queryParams.get('mode');
    const oobCode = queryParams.get('oobCode');

    console.log("🛠️ Debug: mode =", mode);
    console.log("🛠️ Debug: oobCode =", oobCode);

    if (!mode || !oobCode) {
      setError('Invalid or expired action code.');
      setIsLoading(false);
      return;
    }

    /**
     * ✅ Refresh user token before making Firebase requests
     */
    const refreshToken = async () => {
      if (user) {
        try {
          await getIdToken(user, true); // Force token refresh
          console.log("🔄 User token refreshed successfully");
        } catch (error) {
          console.error("🚨 Error refreshing user token:", error);
          setError("Session expired. Please log in again.");
          navigate("/login"); // Redirect to login if refresh fails
          return;
        }
      }
    };

    refreshToken()
      .then(() => {
        if (mode === 'resetPassword') {
          verifyPasswordResetCode(auth, oobCode)
              .then(() => {
                  navigate(`/resetpass?oobCode=${oobCode}`); // ✅ Redirecting to the correct page
              })
              .catch(() => {
                  setError('Invalid or expired action code.');
                  setIsLoading(false);
              });
      
        } else if (mode === 'verifyEmail') {
          applyActionCode(auth, oobCode)
            .then(() => {
              console.log("✅ Email verification successful!");
              setMessage('Your email is verified!');

              // ✅ Update email verification state in authContext
              if (setIsEmailVerified) {
                setIsEmailVerified(true);
              }

              // ✅ Update localStorage so all tabs recognize verification
              localStorage.setItem("emailVerified", JSON.stringify(true));

               // ✅ Notify other tabs that email verification is complete
               if (verificationChannel.current) {
                verificationChannel.current.postMessage({ emailVerified: true });
              }

              // ✅ Send admin notification
            // ✅ Send admin notification
            if (user?.uid) {
              notifyAdminUserVerified({
                email: user.email,
                firstName: userData?.firstName || "",
                lastName: userData?.lastName || "",
                companyName: userData?.companyName || "",
                userType: userData?.userType || "unknown",
                uid: user.uid,
              })
              .then(() => {  
                console.log("✅ Admin notification sent successfully!");

                // ✅ Redirect after 3 seconds
                setTimeout(() => navigate('/login'), 3000);
                setIsLoading(false);
              })
              .catch((error) => {
                console.error("🚨 Email verification error:", error);
                setError("Invalid or expired verification link.");
                setIsLoading(false);
              });
            } else {
              // ✅ Handle case where user is not available
              setTimeout(() => navigate('/login'), 3000);
              setIsLoading(false);
            }
          })
          .catch(error => {
            console.error("🚨 Error during verification process:", error);
            setError("Verification process failed. Please try again.");
            setIsLoading(false);
          });
      } else {
        setError('Invalid action mode.');
        setIsLoading(false);
      }
    });

     // ✅ Cleanup function for BroadcastChannel
     return () => {
      if (verificationChannel.current) {
        verificationChannel.current.close();
        console.log("📢 BroadcastChannel closed.");
      }
    };
  }, [location, navigate, setIsEmailVerified, user, firstName, lastName, companyName, userType]
  );  // ✅ Fix: JSON.stringify(userData)


  /**
 * ✅ Function to notify the admin that a user has verified their email
 */
  const notifyAdminUserVerified = async (userData) => {
    if (!userData.uid) {
      console.error("🚨 Missing user UID! Cannot send email.");
      return Promise.reject("User UID is missing");
    }

    try {
      console.log("📤 Sending email notification to admin...");
      
      const response = await fetch("http://localhost:5000/send-user-signup-email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      });

      const data = await response.json();
      console.log("✅ Server Response:", data);

      if (!response.ok) {
        console.error("🚨 Email API Error:", data);
        return Promise.reject("Email API Error");
      }

      return data;
    } catch (error) {
      console.error("🚨 Fetch Error:", error);
      return Promise.reject(error);
    }
  };

  
  return (
    <div className="form-style">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <h1>{message || error}</h1>
          {message && (
            <p style={{ fontSize: '16px', color: '#2c3e50' }}>
              Your email is verified! You are now being redirected to log in to your account. <br></br>
              <br></br>
              <br></br>
            Having trouble? Contact us at <span className="email-style">signup@neufluence.com</span>.</p>
          
            
          )}
        </div>
      )}
    </div>
  );
};

export default EmailisVerifiedMsg;

