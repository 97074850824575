

import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import Modal from "../../Modal";
import '../../../cssFiles/creds.css';
import '../../../cssFiles/navbar.css';


const Credsdesc = () => {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false); 
    const [modalContent, setModalContent] = useState(""); 
    const [modalMessage, setModalMessage] = useState(''); 
    
    // Function to handle navigation
    const handleNavigation = (path) => {
        navigate(path);
    }

    // Function to open the modal
    const openModal = (content, message) => {
        setModalContent(content);
        setModalMessage(message);
        setShowModal(true);
    }

    // Function to close the modal
    const handleCloseModal = () => {
        setShowModal(false);
        setModalContent('');
        setModalMessage('');
    }

    return ( 
        <div className="CREDITZ-container">
            <div className="CREDITZ-box">
                <div className="createhead">
                    <h2 style={{ fontSize: '30px', color: '#fc8320',textAlign: 'center', }}>Standalone CREDITZ</h2>
                    <p>Below are the CREDITZ available without the purchase of a Full Service Plan.</p>
                
                    <p>Click a button below to learn more about each CREDITZ type.</p>

                </div>

                <br></br>
                
                <div className="button-groupCreditz">
                    <div className="button-rowCreditz">
                        <button className="CREDITZ-button consultation" 
                                onClick={() => openModal (
                                'Consultation', 
                                <div>
                                    <p>Get expertise around strategies to help you structure and execute your campaign.</p>
                                    <p style={{ textAlign: 'left' }}>Includes:</p>
                                    <ul style={{ textAlign: 'left' }}>
                                        <li>1-hour call.</li>
                                        <li>Up to 3 topics covered.</li>
                                        <li>Notes from session with action items.</li>
                                    </ul>
                                </div>
                            )}>
                                Campaign Consultation*
                            <p className="creditz-agreements">12 CREDITZ</p>
                        </button>

                        <button className="CREDITZ-button execute-agreements" 
                                onClick={() => openModal(
                                    'Execute Creator Agreements',
                                    <div>
                                      <p style={{ textAlign: 'left' }}>Includes:</p>
                                      <ul style={{ textAlign: 'left' }}>
                                        <li>Draft 1 creator agreement. </li>
                                        <li>Execution of agreements with creators.</li>
                                        <li>Tracking of the status agreements. </li>
                                        <li>Ensure agreement execution. </li>
                                      </ul>
                                    </div>
                                  )
                                }
                              >
                                Execute Creator Agreements
                                <p className="creditz-agreements">Starting at 15 CREDITZ*</p>
                              </button>
                              <button
                                className="CREDITZ-button execute-invoice-payments"
                                onClick={() =>
                                  openModal(
                                    'Execute Creator Invoice/Payments',
                                    <div>
                                      <p style={{ textAlign: 'left' }}>Includes:</p>
                                      <ul style={{ textAlign: 'left' }}>
                                        <li>Track receipt of creator campaign invoices. </li>
                                        <li>Execute creator payments.*</li>
                                        <li>Provide a record of all payments.</li>
                                      </ul>
                                    </div>
                                  )
                                }
                              >
                                Execute Creator Invoice/Payments
                                <p className="creditz-agreements">Starting at 15 CREDITZ*</p>
                              </button>
                            </div>
                            <div className="button-rowCreditz">
                              <button
                                className="CREDITZ-button draft-campaign-brief"
                                onClick={() =>
                                  openModal(
                                    'Draft a Campaign Brief',
                                    <div>
                                      <p style={{ textAlign: 'left' }}>Includes:</p>
                                      <ul style={{ textAlign: 'left' }}>
                                        <li>1 campaign brief.</li>
                                        <li>1 revision.</li>
                                        <li>Additional revisions are 5 CREDITZ.</li>
                                      </ul>
                                    </div>
                                  )
                                }
                              >
                                Draft a Campaign Brief
                                <p className="creditz-agreements">10 CREDITZ</p>
                              </button>
                              <button
                                className="CREDITZ-button campaign-pipeline-management"
                                onClick={() =>
                                  openModal(
                                    'Campaign Pipeline Management',
                                    <div>
                                      <p style={{ textAlign: 'left' }}>Includes:</p>
                                      <ul style={{ textAlign: 'left' }}>
                                        <li>1 creator pipeline </li>
                                        <li>Tracking creator outreach status, reponses, fee requirements, and notes.</li>
                                        <li>Notifications: daily, weekly, or bi-weekly pipeline status updates.</li>
                                      </ul>
                                    </div>
                                  )
                                }
                              >
                                Campaign Pipeline Management
                                <p className="creditz-agreements">Starting at 15 CREDITZ*</p>
                              </button>
                              <button
                                className="CREDITZ-button collect-content-deliverables"
                                onClick={() =>
                                  openModal(
                                    'Manage Content Deliverables',
                                    <div>
                                      <p style={{ textAlign: 'left' }}>Includes:</p>
                                      <ul style={{ textAlign: 'left' }}>
                                        <li>-1 content pipeline </li>
                                        <li>Track content submissions.</li>
                                        <li>Ensure content meets client requirements.</li>
                                        <li>Send content to client for approval.</li>
                                      </ul>
                                    </div>
                                  )
                                }
                              >
                                Manage Content Deliverables
                                <p className="creditz-agreements">Starting at 20 CREDITZ*</p>
                              </button>
                              
                            </div>
                         
                          </div>
                          <div>
                          <button style={{ fontSize: '16px', fontStyle: 'bold'}} className="buy-button" onClick={() => handleNavigation('/crdtzpay')}>
                            Buy CREDITZ
                        </button>
                        </div>
                         <p style={{ fontSize: '11px', textAlign: 'left', marginBottom: 'none', lineHeight: '0' }}>*CREDITZ amount is based on the size of the campaign.</p>
                        <p style={{ fontSize: '11px', textAlign: 'left', marginBottom: '0',lineHeight: '0' }}>*24 CREDITZ maximum per campaign.</p>
                       <p style={{ fontSize: '11px',textAlign: 'left', marginTop: 'none', lineHeight: '0' }}>**includes management of influencer budget (as paid by client). </p>
                       
                         {/* Back to Campaign Page Link */}
  <div className="back-to-campaign">
    <button 
      type="button" 
      className="back-to-campaign-button" 
      onClick={() => {

        navigate("/dashboardb");
      }}
    >
      Back to Main Dashboard
    </button>
  </div>
                        </div>
            <Modal className="modal-content" show={showModal} handleClose={handleCloseModal}>
                <div >
                    <h2>{modalContent}</h2>
                    <p>{modalMessage}</p>
                    <div className="modal-button-row">
                        <button className="confirmout-button" onClick={() => handleNavigation('/crdtzpay')}>
                            Buy CREDITZ
                        </button>
                        <button className="buy-button" onClick={handleCloseModal}>
                            Close
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default Credsdesc;
