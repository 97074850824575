

import { useState } from "react";
import { Formik, Form, Field } from "formik";
import { TestCampaignSchema} from '../../../utils/formValidationSchemas';
import { useAuth } from "../../../contexts/authContext"; 
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../../LoadingSpinner';
import IndustryMulti from '../../IndustryMulti';
import "../../../cssFiles/questionnaire.css";





/**
 * ✅ TestCampaign Component
 * This component renders a form for users to input campaign details.
 * Features:
 * - Step-by-step form fields
 * - Review modal before final submission
 * - Confirmation modal after successful submission
 */
        export default function TestCampaign() {
          const {  loading, saveTestCampaign } = useAuth();
          const navigate = useNavigate();
          const [step, setStep] = useState(0); // Track current step
          const [reviewModalOpen, setReviewModalOpen] = useState(false); // State to show/hide the review modal
          const [successModalOpen, setSuccessModalOpen] = useState(false); // State to show/hide the success modal
        

          if (loading) return <LoadingSpinner />;





          // List of form fields in step-by-step order
          const formFields = [
            { name: "campaignName", label: "Campaign Name", subLabel: "Enter a name for your campaign (it can be updated later).", type: "text" },
            { name: "campaignDesc", label: "Campaign Description", subLabel: "Provide as much detail as you can.", type: "textarea" },
            { name: "creatorsNum", label: "Target # of Creators to participate in your campaign.", type: "number" },
            { name: "postsRequired", label: "What is the number of posts required for each creator?", type: "number" },
            { name: "postingStart", label: "What date should creators start posting?", type: "date" },
            { name: "postingEnd", label: "What date does your campaign end?", type: "date" },
            { name: "industryMulti", label: "Choose the industries of the creators you want.", subLabel: "Select up to 2 industries.", type: "custom", isMulti: true  }, // No component reference needed
            { name: "campaignBudget", label: "What is your overall budget for a test campaign?",subLabel: "This includes fees for creators and for help running the test campaign.", type: "currency" },
            { name: "feeRange", label: "What is the fee range you will pay a creator? *", subLabel: "ie: the lowest/highest you will pay for the creator to create 1-2 pieces of content.", type: "text" },
            { name: "campaignPreference", label: "Do you have a team member to help you run your campaign?", subLabel: "Some expert help is included with a test campaign.", type: "select", 
              options: [
                { value: "", text: "Select an option" },
                { value: "independent", text: "Yes, we have a team member to run the campaign." },
                { value: "expert", text: "We need an expert to run the entire campaign." }
              ] 
            },
          ];

          // Calculate progress percentage
          const progressPercentage = ((step + 1) / formFields.length) * 100;

  return (
    <Formik
      initialValues={{
        campaignName: "",
        campaignDesc: "",
        creatorsNum: "",
        industryMulti: [],
        postsRequired: "",
        postingStart: "",
        postingEnd: "",
        feeRange: "",
        campaignBudget: "",
        campaignPreference: "",
      }}
      validationSchema={TestCampaignSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {


   try {
          await saveTestCampaign(values);
          setReviewModalOpen(false); // ✅ Ensure review modal closes before success modal
          setSuccessModalOpen(true); // Show success modal
          resetForm();
          alert('Test campaign created!');
  
  
          // Redirect after success modal
          setTimeout(() => {
            setSuccessModalOpen(false);
            navigate("/dashboardb");
          }, 5000);
        } catch (error) {
          console.error("Error saving campaign:", error);
        } finally {
          setSubmitting(false); // ✅ Always reset Formik submission state
        }
        }}
    >
        {({ isSubmitting, values, errors, touched, setFieldValue, setFieldTouched, setTouched, setErrors }) => (
       <Form className="questionnaire-container">
  <div >
    <div className="campaigns-style">
   

      <h2 style={{ color: "#fc8320"}}>Tell Us About Your Test Campaign</h2>
      <p>Complete this short overview and we will provide a review of your test campaign.</p>
      <p>You will be able to update all of your details.</p>

         {/* Progress Bar */}
         <div className="progress-container">
        <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
      </div>


      {/* Show only the current field */}
      <div className="field-container">
        <label htmlFor={formFields[step].name}>{formFields[step].label}</label>

        {/* SubLabel positioned below the label */}
        {formFields[step].subLabel && (
          <p style={{ fontSize: "14px", color: "#6c757d", marginTop: "0px", marginBottom: "0px", textAlign: "left" }}>
            {formFields[step].subLabel}
          </p>
        )}

        {/* Render input fields dynamically based on type */}
        {formFields[step].type === "custom" ? (

          <IndustryMulti
          name="industryMulti"
          isMulti={formFields[step].isMulti}
          onChange={(value) => setFieldValue("industryMulti", value)}
          onBlur={() => setTouched({ industryMulti: true })} // ✅ Ensures validation runs
          />


        ) : formFields[step].type === "select" ? (
          <Field as="select" name={formFields[step].name}>
            {formFields[step].options.map((option) => (
              <option key={option.value} value={option.value}>{option.text}</option>
            ))}
          </Field>
        ) : formFields[step].type === "textarea" ? (
          <Field as="textarea" name={formFields[step].name} />
        ) : formFields[step].type === "currency" ? (
          <input
            type="text"
            name={formFields[step].name}
            placeholder="$0"
            value={values[formFields[step].name] || ""}
            onChange={(event) => {
              let numericValue = parseFloat(event.target.value.replace(/[^0-9.]/g, "")) || 0;
              setFieldValue(formFields[step].name, numericValue);
            }}
            className="currency-input"
          />
        ) : (
          <Field type={formFields[step].type} name={formFields[step].name} />
        )}

        {/* Show error message only for the active step */}
        {touched[formFields[step].name] && errors[formFields[step].name] && (
          <div className="error-message">{errors[formFields[step].name]}</div>
        )}
      </div>

      {/* Navigation Buttons */}
      
      <div className="navigation-links">
        {step > 0 && (
          <button type="button" 
          className="navigation-links" 
          style={{background: "none", border: "none", marginTop: "5"}}
          onClick={() => {
            setStep(step - 1);
            setErrors({});
            setTouched({});
          }}>
            ← Previous
          </button>
        )}

        {step < formFields.length - 1 ? (
         <button
         type="button"
         className="navigation-links"
         style={{background: "none", border: "none"}}
         onClick={async () => {
           await setFieldTouched(formFields[step].name, true, true);
          
       
           if (!errors[formFields[step].name]) {
             setStep(step + 1);
           }
         }}
       >
         Next →
       </button>
        ) : (
          <button type="button" className="form-button" onClick={() => setReviewModalOpen(true)}>
            Review Submission
          </button>
        )}
      </div>


    </div>
      {/* Back to Campaign Page Link */}
  <div className="back-to-campaign">
    <button 
      type="button" 
      className="back-to-campaign-button" 
      onClick={() => {
        localStorage.setItem("campaignSuccessFormData", JSON.stringify(values));
        navigate("/dashboardb");
      }}
    >
      Back to Main Dashboard
    </button>
  </div>
  </div>

  {/* Review Modal */}
  {reviewModalOpen && (
    <div className="modal">
      <div className="modal1-content">
 
      <button 
  className="close-button" 
  onClick={() => setReviewModalOpen(false)}
  style={{
    position: "absolute",
    top: "5px",
    marginLeft: "230px",
    background: "none",
    border: "none",
    fontWeight: "bold",
    fontSize: "20px",
    cursor: "pointer",
    color: "#d211d2"
  }}
  aria-label="Close Review Modal"
>
  X
</button>
     
        <div className="scrollable-content">
          <h3>Review Your Submission</h3>
          <p>Scroll down to review your entries. Click "Edit" to go back and make changes, or "Confirm Submission" to submit.</p>

          {/* Display form values with proper formatting */}
          {formFields.map((field) => (
            <div key={field.name} className="review-field">
              <label><strong>{field.label}:</strong></label>

              {/* Handle Multi-Select (industryMulti) */}
              {field.name === "industryMulti" ? (
                <p className="review-value">
                  {Array.isArray(values[field.name]) && values[field.name].length > 0
                    ? values[field.name].join(", ") // ✅ Convert array to readable string
                    : "Not Provided"}
                </p>
              
              /* Handle Single-Select (Campaign Preference) */
              ) : field.name === "campaignPreference" ? (
                <p className="review-value">
                  {field.options.find(option => option.value === values[field.name])?.text || "Not Provided"}
                </p>

              /* Handle Object Values (e.g., feeRange, campaignBudget) */
              ) : typeof values[field.name] === "object" && values[field.name] !== null ? (
                <p className="review-value">{JSON.stringify(values[field.name])}</p> // ✅ Convert object to string
              
              /* Default Handling for Other Fields */
              ) : (
                <p className="review-value">
                  {values[field.name] ? values[field.name].toString() : "Not Provided"}
                </p>
              )}
            </div>
          ))}

          {/* Buttons for Editing or Confirming Submission */}
          <div className="modal-buttons">
 
          <button 
            type="submit"
            className="confirmout-button"
            disabled={isSubmitting} // Disable while submitting
          >
            {isSubmitting ? "Submitting..." : "Confirm Submission"}
          </button>


          </div>
        </div>
      </div>
    </div>
  )}


  {/* Success Modal (Auto-Closes after 5 seconds) */}
  {successModalOpen && (
    <div className="modal">
      <div className="modal-content">
        <h2 style={{color: '#fc8320', fontSize: '20'}}>Submission Successful!</h2>
        <p>Your test campaign details have been successfully submitted. We will respond to you via email with an update.</p>
        <p>You are now being redirected back to your dashboard.</p>
      </div>
    </div>
  )}
</Form>

      )}
    </Formik>
  );
}