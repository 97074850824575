// This code is for Resetting Your Password

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebase';
import { 
    verifyPasswordResetCode, 
    confirmPasswordReset 
} from 'firebase/auth';

import Modal from '../Modal';
import LoadingSpinner from '../LoadingSpinner';

/**
 * ResetPassword component handles the password reset process.
 * It verifies the password reset code, allows the user to enter a new password,
 * and handles the password reset operation with Firebase Authentication.
 */

const ResetPassword = () => {
     // State for form fields allowing reset of the fields for dynamic values

     const [password, setPassword] = useState('');
     const [confirmPassword, setConfirmPassword] = useState('');
     const [passwordError, setPasswordError] = useState('');
     const [confirmPasswordError, setConfirmPasswordError] = useState('');
     const [showPassword, setShowPassword] = useState(false);
     const [isLoading, setIsLoading] = useState(true); // Loading state
     const [showModal, setShowModal] = useState(false);
     const [error, setError] = useState('');
     const [successMessage, setSuccessMessage] = useState('');

     // Initialize Navigate and Authentication
    
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      // Parse URL parameters
      const queryParams = new URLSearchParams(location.search);
      const mode = queryParams.get('mode');
      const oobCode = queryParams.get('oobCode');
  
      // Check if the mode is resetPassword and the action code (oobCode) is present
      if (mode === 'resetPassword' && oobCode) {
        verifyPasswordResetCode(auth, oobCode)
          .then(() => {
            // Action code is valid, show the password reset form
            setIsLoading(false);
          })
          .catch((error) => {
            // Invalid or expired action code, show error
            setError('Invalid or expired action code.');
            setIsLoading(false);
          });
      } else {
        // Redirect to home page or show an error if mode or oobCode is missing
      
        setIsLoading(false);
      }
    }, [location]);

  

      /**
   * Handle form submission for password reset.
   * @param {Event} e - The form submission event.
   */
      const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');
    
        // Validate passwords
        const isPasswordValid = validatePassword(password);
        const isConfirmPasswordValid = validateConfirmPassword(password, confirmPassword);
    
        if (!isPasswordValid || !isConfirmPasswordValid) {
            return;
        }
    
        setIsLoading(true);
    
        try {
            const actionCode = getActionCodeFromURL();
            await handleResetPassword(auth, actionCode, password);
    
            showSuccessModal();
            
            setTimeout(() => {
                navigate('/login'); // ✅ Redirecting after delay
            }, 3000);
        } catch (err) {
            setError("Failed to reset password. " + err.message);
        } finally {
            setIsLoading(false);
        }
    };
    
    
  
  /**
   * Extract action code (oobCode) from the URL.
   * @returns {string} The action code.
   */
  const getActionCodeFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('oobCode');
  };



    /**
   * Show success modal and reset loading state.
   */

    // Call this function when the password reset is successful
    const showSuccessModal = () => {
        setSuccessMessage('Password has been reset successfully. You are now being redirected to log in.');
        setShowModal(true);
        setIsLoading(true); 
    };

     /**
   * Handle the password reset process with Firebase Authentication.
   * @param {Object} auth - The Firebase Auth instance.
   * @param {string} actionCode - The action code from the URL.
   * @param {string} newPassword - The new password entered by the user.
   * @throws Will throw an error if password reset fails.
   */
    const handleResetPassword = async (auth, actionCode, newPassword) => {
        try {
            const email = await verifyPasswordResetCode(auth, actionCode);
            await confirmPasswordReset(auth, actionCode, newPassword);
            console.log("Password reset for:", email);
        } catch (err) {
            throw err;
        }
    };

   

   /**
   * Validate the password based on given criteria.
   * @param {string} password - The password to validate.
   * @returns {boolean} True if the password is valid, false otherwise.
   */

const validatePassword = (password) => {
        const errors = [];
        if (password.length < 7) {
          errors.push("Password is invalid. Your password must be at least 7 characters long.");
        }
        if (!/[A-Z]/.test(password)) {
          errors.push("Password must contain at least one uppercase letter.");
        }
        if (!/[0-9]/.test(password)) {
          errors.push("Password must contain at least one number.");
        }
        // Add more conditions as required

        setPasswordError(errors.join(" "));
        return errors.length === 0;
    };

     /**
   * Validate the confirmed password matches the original password.
   * @param {string} password - The original password.
   * @param {string} confirmPassword - The confirmed password.
   * @returns {boolean} True if the passwords match, false otherwise.
   */

    const validateConfirmPassword = (password, confirmPassword) => {
        if (password !== confirmPassword) {
          setConfirmPasswordError("Passwords do not match.");
          return false;
        }
        setConfirmPasswordError('');
        return true;
      };



      // Show Password when chckbox is clicked.

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
  };

     

// Password Reset Form

    return ( 

        <div className="form-style">
          
        <form onSubmit={handleSubmit}>
        
        <br></br>

        <h2>Reset Your Password</h2>
        
        <br></br>
        <br></br>
        {error && <div style={{ color: 'red' }}>{error}</div>}
       
        {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
        
        <label> Password
            <input 
            type={showPassword ? "text" : "password"}
            id="password" 
            name="password" 
            required
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            />
            {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
         </label>

         <label> Confirm Password
            <input 
            type={showPassword ? "text" : "password"}
            id="confirm_password" 
            name="confirmPassword" 
            autoComplete="new-password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword (e.target.value)}
            placeholder="Confirm Password"
            />
            {confirmPasswordError && <div style={{ color: 'red' }}>{confirmPasswordError}</div>}
         
         </label>

            <label>
        <input 
        type="checkbox" 
        checked={showPassword}
        onChange={toggleShowPassword}
        id="showPassword"
        /> 
        Show Password
        </label>


        <button type="submit" disabled={isLoading}>
          {isLoading ? <LoadingSpinner /> : "Reset Password"}
        </button>

        </form>

        <Modal show={showModal} handleClose={() => setShowModal(false)}>
                <p>{successMessage}</p>
                <LoadingSpinner aria-label="Loading..." /> {/* Display spinner with accessibility improvement with aria-label */}
            </Modal>

        </div>
           
    );
  }

export default ResetPassword