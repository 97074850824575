

import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
 
  const navigate = useNavigate();

    const handleNavigation = (path) => {
      navigate(path);
    };


    return (
      <div className="emailMessage form-style">
          <h1 style={{ color: '#fc8320' }}>Unauthorized Access</h1>
       <div>
        <p style={{fontSize: 18, fontWeight: 'bold'}}>We're sorry! <br></br> You do not have permission to view this page. If you received this page in error, please contact us at the email below.</p>
        </div>
        
    
   

        <br />
      <p>Still having trouble? Contact us at <span className="email-style">signup@neufluence.com</span>.</p>
          
  
      <button 
            className="submit-button" 
            onClick={() => handleNavigation('/dashboardb')}
          >
            Back to Main Dashboard
          </button>
 

      </div>
    );
  };
  
  export default Unauthorized;

