import React from "react";
import { useFormikContext } from "formik";

/**
 * IndustrySelector component for selecting business industry.
 * Supports both single-select and multi-select modes.
 *
 * @param {string} name - The Formik field name.
 * @param {boolean} isMulti - Whether multiple industries can be selected.
 */
const IndustrySelector = ({ name, isMulti = false }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext();

  const handleChange = (event) => {
    if (isMulti) {
      const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
      setFieldValue(name, selectedOptions.length > 0 ? selectedOptions : []);
    } else {
      setFieldValue(name, event.target.value);
    }
  };

  return (
    <div className="industry-container">
      

      <select
        id={name}
        name={name}
        multiple={isMulti}
        value={values[name] || (isMulti ? [] : "")} // Ensures a valid default value
        onChange={handleChange}
        required
      >
        {!isMulti && <option value="">Select your business industry</option>}
        <option value="automotive">Automotive</option>
        <option value="beauty">Beauty</option>
        <option value="business">Business</option>
        <option value="entertainment">Entertainment</option>
        <option value="fashion">Fashion</option>
        <option value="finance">Finance</option>
        <option value="food">Food</option>
        <option value="gaming">Gaming</option>
        <option value="health">Health</option>
        <option value="homeImprovement">Home Improvement</option>
        <option value="lifestyle">Lifestyle</option>
        <option value="other">Other</option>
        <option value="parenting">Parenting</option>
        <option value="pet">Pet</option>
        <option value="religion">Religion</option>
        <option value="sportsFitness">Sports/Fitness</option>
        <option value="tech">Tech</option>
        <option value="travel">Travel</option>
        <option value="wellness">Wellness</option>
      </select>
   
   
     {/* ✅ Display error message */}
     {touched[name] && errors[name] && (
        <div className="error-message">{errors[name]}</div>
      )}
    </div>
  );
};

export default IndustrySelector;
