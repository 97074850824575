




// SidePanel.js

import { Link } from 'react-router-dom';
import '../../../cssFiles/index.css'; // Assuming your CSS file is named Dashboard.css


const SidePanel = () => {

   
    return (
        <div className="sidebar">

            <Link to="/fsoverview">Full Service Overview</Link>
            <Link to="/fspricing">Pay for Full Service Campaign</Link>
          
         
           
            
        </div>
    );
};

export default SidePanel;