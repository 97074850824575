import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import LocationSelector from "../LocationSelector";
import { useAuth } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { BrandSchema } from "../../utils/validationSchemas";
import ReCAPTCHA from "react-google-recaptcha";
import "../../cssFiles/questionnaire.css";


const SignUpB = () => {
  const [showPassword, setShowPassword] = useState(false); // Show password fields after location selection
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(0);
  
  const steps = [
    { name: "firstName", label: "First Name *", type: "text" },
    { name: "lastName", label: "Last Name *", type: "text" },
    { name: "businessName", label: "Business Name *", type: "text" },
    { name: "businessWebsite", label: "Business Website *", type: "url" },
    { name: "location", label: "What is your business location? *", component: LocationSelector },
    { 
      name: "credentials", 
     
      fields: [
        { name: "email", label: "Email Address *", type: "email" },

        { name: "password", label: "Password *", type: "password" },
        { name: "confirmPassword", label: "Confirm Password *", type: "password" }
      ]
    }
  ];


        // Calculate progress percentage
        const progressPercentage = ((step + 1) / steps.length) * 100;


  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleNext = (values, errors, setTouched) => {
    const fieldName = steps[step].name;  // Get current step field name
    
    if (fieldName === "credentials") {
      // Validate all fields inside credentials step
      const credentialsFields = ["email", "password", "confirmPassword"];
      const hasErrors = credentialsFields.some((field) => errors[field]);

      if (hasErrors) {
        setTouched(credentialsFields.reduce((acc, field) => ({ ...acc,[field]: true }), {}));
        return;
      }
    } else if (errors[fieldName]) {
      setTouched({ [fieldName]: true });
      return;
    }

    if (fieldName === "location") setShowPassword(true); // Show password fields after location
    setStep((prev) => prev + 1);
  };

  const handlePrevious = () => setStep((prev) => prev - 1);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        businessName: "",
        businessWebsite: "https://",
        email: "",
        password: "",
        confirmPassword: "",
        country: "",
        state: "",
        city: "",
        agreedToTerms: false,
        fakeField: "",
      }}
      validationSchema={BrandSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if (values.fakeField) {
          console.warn("🚨 Honeypot triggered. Possible bot detected.");
          return;
        }

        if (!recaptchaToken) {
          alert("Please complete the reCAPTCHA");
          return;
        }

        setIsSubmitting(true); //Disable submit button

        try {
          const response = await signUp({ ...values, userType: "brand" });
          if (response?.success) {
            resetForm();  //reset form on success
            navigate("/emailmsg");  //redirect user
          }
        } catch (error) {
          console.error(error);
        }
        setIsSubmitting(false);
      }}
    >
      {formik => { 
        const { values, errors, setTouched } = formik; 

        return (
          <Form className="questionnaire-container">
            <div className="questionnaire-style">
              <div className="createhead">
                <h2>Build Your Brand Profile</h2>
              </div>
              <div className="questionnaire-style-heading">
                <p>
                  Complete the information below and enter a password. <br />
                  <br /> After clicking "Create My Account", you will be directed to
                  your email to confirm your account.
                </p>
              </div>

             
               {/* Progress Bar */}
      <div className="progress-container">
        <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
      </div>
      
     <br></br>
              <div className="scrollable-content">
             
                {steps.map((field, index) => (
                  step === index && (
                  
                  <div key={field.name}>
                      <label htmlFor={field.name}>{field.label}</label>
                      {field.component ? (
                        field.name === "location" ? (
                          <LocationSelector formik={formik} />
                        ) : (
                          <field.component />
                        )
                        
                      ) : field.name === "credentials" ? (
                        <>
                         <br></br>
                          {field.fields.map((subField) => (
                            <div key={subField.name} className="field-container"> {/* ✅ Add CSS class */}
                              <label htmlFor={subField.name}>{subField.label}</label>
                              <br></br>
                              <Field 
                                type={subField.name === "password" || subField.name === "confirmPassword"
                                  ? showPassword ? "text" : "password"
                                  : subField.type}
                                id={subField.name} 
                                name={subField.name} 
                                autoComplete="off"
                              />
                              <ErrorMessage name={subField.name} component="div" className="error-message" />
                            </div>
                          ))}
                          
                          {/* ✅ Show Password Checkbox */}
                          <label>
                            <input
                              type="checkbox"
                              checked={showPassword}
                              onChange={toggleShowPassword}
                              id="showPassword"
                            />
                            Show Password
                          </label>
                        </>
                      ) : (
                        <Field type={field.type} id={field.name} name={field.name} autoComplete="off" />
                      )}
                      <ErrorMessage name={field.name} component="div" className="error-message" />
                    </div>
                  )
                ))}

                  <br></br>
                  {/* reCAPTCHA and Terms Agreement on the Last Step */}
              {step === steps.length - 1 && (
                <>
                  {/* Google reCAPTCHA */}
                  <ReCAPTCHA
                    sitekey="6LftcvopAAAAAIjDtKRd6oYNjRLuLJUFZ3XL9dsp"
                    onChange={setRecaptchaToken}
                  />

                  {/* Terms Agreement */}
                  <div className="term-links">
                    <ErrorMessage name="agreedToTerms" component="div" className="error-message" />
                    <Field type="checkbox" name="agreedToTerms" />
                    By creating an account, you agree to our{" "}
                    <a href="https://neufluence.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Service</a>,{" "}
                    <a href="https://neufluence.com/privacy_policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and our default notifications settings.
                  </div>
                </>
              )}
           

                {/* Navigation Buttons */}
                <div className="navigation-links">
                {step > 0 && (
                  <span className="navigation-links" onClick={handlePrevious} role="button" tabIndex="0">
                    ← Previous
                  </span>
                )}
                {step < steps.length - 1 ? (
                  <span className="navigation-links" onClick={() => handleNext(values, errors, setTouched)} role="button" tabIndex="0">
                    Next →
                  </span>
                  
                ) : (
                  
                  <div >
                  <button  type="submit" disabled={isSubmitting || !recaptchaToken}>
                    {isSubmitting ? "Submitting..." : "Create My Account"}
                  </button>
                  </div>
                )}
              </div>
<br></br>
              </div>
            </div>
          </Form>
        );  
      }}
    </Formik>
  );
};

export default SignUpB;
