import { createContext, useContext, useEffect, useState, useRef } from "react";
import { 
  auth, 
  db  } from '../firebase'; // Ensure db is your Firestore instance

import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged, 
  sendEmailVerification
 } from 'firebase/auth';
 import { 
  doc, 
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  arrayUnion, // tracks login history and devices
  collection,
  serverTimestamp
 } from 'firebase/firestore';

 
 const AuthContext = createContext();
 
 export function AuthProvider({ children }) {
   const [user, setUser] = useState(null);
   const [isAdmin, setIsAdmin] = useState(false); // ✅ New State for Admin Check
   const [userType, setUserType] = useState(null);
   const [loading, setLoading] = useState(true);
   const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(false);
   const [userData, setUserData] = useState(null);
   const [isEmailVerified, setIsEmailVerified] = useState(false);
 
   // Broadcast channel for email verification sync
   const verificationChannel = useRef(new BroadcastChannel("email_verification_channel"));

 
   /**
    * ✅ Fetch user document from Firestore upon login
    * ✅ Detects email verification changes in real-time
    */
  
// ✅ New code

 /**
   * ✅ Initialize BroadcastChannel ONLY in useEffect
   * - Ensures it runs in the browser environment.
   */
 useEffect(() => {
  if (typeof window !== "undefined" && verificationChannel.current === null) {
    verificationChannel.current = new BroadcastChannel("email_verification_channel");

    if (verificationChannel.current) {
      verificationChannel.current.onmessage = (event) => {
        if (event.data?.emailVerified) {
          setIsEmailVerified(true);
          localStorage.setItem("emailVerified", JSON.stringify(true));
        }
      };
    }
  }

  return () => {
    if (verificationChannel.current) {
      verificationChannel.current.close();
      verificationChannel.current = null; // ✅ Ensure it's fully cleaned up
      console.log("✅ BroadcastChannel closed on unmount");
    }
  };
}, []); // ✅ Empty dependency array ensures it's initialized only once

         useEffect(() => {
          const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
              setUser(currentUser);

               // ✅ Correct Placement: Fetch email verification status *before* accessing Firestore
               const emailVerified = currentUser.emailVerified;
        
              const userRef = doc(db, "users", currentUser.uid);
              const userSnap = await getDoc(userRef);
        
              if (userSnap.exists()) {
                const userData = userSnap.data() || {}; // ✅ Ensures we always get an object
        
                setUserData(userData);
                setUserType(userData.userType || "");
        
                 // ✅ Determine if the user is an admin
          const isAdminUser = userData.userType === "admin";
          setIsAdmin(isAdminUser); // ✅ Update `isAdmin` state

            // ✅ Ensure Firestore `emailVerified` is updated only if necessary
            if (emailVerified && !userData.emailVerified) {
              try {
              
                await updateDoc(userRef, { emailVerified: true });
              } catch (error) {
                console.error("🚨 Error updating email verification in Firestore:", error);
              }
            }

                // ✅ Store email verification status in state and local storage
          setIsEmailVerified(emailVerified);
          localStorage.setItem("emailVerified", JSON.stringify(emailVerified));
  
          // ✅ Update lastSignon timestamp when user logs in
          await updateDoc(userRef, { lastSignon: serverTimestamp() });
  
          // ✅ Notify other tabs if email was just verified
          if (emailVerified && verificationChannel.current) {
            try {
              verificationChannel.postMessage({ emailVerified: true });
             
            } catch (error) {
              console.error("🚨 BroadcastChannel error:", error);
            }
          }
                // ✅ Do NOT set hasCompletedOnboarding here
              } else {
                console.warn("🚨 No Firestore document found for user:", currentUser.uid);
                setUserData(null);
                setUserType(null);
                setIsAdmin(false); // ✅ Reset `isAdmin`
          setIsEmailVerified(false);
              }
            } else {
              setUser(null);
              setUserData(null);
              setUserType(null);
              setIsAdmin(false); // ✅ Reset `isAdmin`
              setIsEmailVerified(false);
              localStorage.removeItem("emailVerified");
            }
        
            setLoading(false);
          });
        
          return () => unsubscribe();
        }, []);
        
        // ✅ Move hasCompletedOnboarding logic into its own useEffect
        useEffect(() => {
          if (!loading && user && userData) {
            setHasCompletedOnboarding(userData.hasCompletedOnboarding ?? false);
          } else {
            setHasCompletedOnboarding(null); // Prevent false modal triggers
          }
        }, [user, userData, loading]);
        
      


     /**
    * ✅ Listen for email verification status from other tabs
    */
     useEffect(() => {
      const channel = verificationChannel.current; // ✅ Store the ref value in a local variable
    
      channel.onmessage = (event) => {
        if (event.data.emailVerified) {
          setIsEmailVerified(true);
          localStorage.setItem("emailVerified", JSON.stringify(true));
        }
      };
    
      return () => {
        channel.close(); // ✅ Use the stored reference value
      };
    }, []);
    
 

 
   /**
    * Sign-up function that saves user data to Firestore.
    * Includes userType (brand/creator) and a createdAt timestamp.
    */
   const signUp = async (values) => {
     const { email, password, firstName, lastName, userType, businessName, businessWebsite, country, state, city } = values;
     
     try {
       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
       const userId = userCredential.user.uid;
 
       // Save additional user data to Firestore
       await setDoc(doc(db, "users", userId), {
         firstName,
         lastName,
         email,
         userType,
         country,
         state,
         city,
         ...(userType === "brand" && { businessName, businessWebsite }), // Include business fields only for brands
         hasCompletedOnboarding: false, // Default value
         agreedToTerms: true,
         createdAt: serverTimestamp(),  //stores when account is created.
         lastSignon: serverTimestamp(), //stores initial login time
       });
 
       // Send email verification
       await sendEmailVerification(userCredential.user);
     
       // Return success so Formik can reset the form
       return { success: true };
     } catch (error) {
       console.error("Sign-up error:", error);
       throw error;
     }
   };
 
   /**
 * Sign-in function
 */
const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user; // ✅ Extract user object

    // ✅ Correctly store login history
    const userRef = doc(db, "users", user.uid);
    await updateDoc(userRef, {
      lastSignon: serverTimestamp(), // ✅ Correct usage
      loginHistory: arrayUnion(new Date().toISOString()) // ✅ Use a JavaScript date instead
    });

    return user; // ✅ Return user object for use in SigninForm.js
  } catch (error) {
    console.error("Error during sign-in:", error);
    throw error;
  }
};

   /** ✅ Save Test Campaign Data to Firestore */
   const saveTestCampaign = async (values) => {
    if (!user?.uid) {
      console.error("🚨 Error: User not authenticated");
      throw new Error("User must be authenticated to save a test campaign.");
    }
  
    try {
      const testCampaignRef = collection(db, `users/${user.uid}/testcampaign`); // ✅ Correct subcollection path
  
      // Log the data before submitting
      console.log("📌 Saving test campaign data:", values);
  
      // Add campaign document with Firestore timestamp
      const docRef = await addDoc(testCampaignRef, {
        ...values,
        createdAt: serverTimestamp(),
        status: "Draft", // ✅ Default campaign status
        testCampaign: "free plan"
      });

    // ✅ Firestore automatically assigns an ID, now update the document with `campaignId`
    await updateDoc(docRef, { campaignId: docRef.id });
  
      console.log(`✅ Test campaign added successfully with ID: ${docRef.id}`);
      return docRef.id;
    } catch (error) {
      console.error("🚨 Error saving test campaign data:", error);
      throw error;
    }
  };
  
  
   /**
    * ✅ Save Brand Questionnaire Data to Firestore
    * @param {Object} values - The form values submitted by the brand user
    */
   const saveBrandQuestionnaire = async (values) => {
     if (!user) return;
 
     try {
       const userRef = doc(db, "users", user.uid);
       await updateDoc(userRef, {
         ...values,
         hasCompletedOnboarding: true,
       });
 
       // Update local state so UI reflects changes immediately
       setUserData((prevData) => ({
         ...prevData,
         ...values,
         hasCompletedOnboarding: true,
       }));
 
       setHasCompletedOnboarding(true);
     } catch (error) {
       console.error("Error saving brand questionnaire data:", error);
     }
   };
 
   
 
   /**
    * ✅ Sign-out function
    */
   const signOutUser = async () => {
    await signOut(auth);
  
    setUser(null);
    setUserData(null);
    setUserType(null);
    setIsEmailVerified(false);
  
    // ✅ Do not explicitly set `hasCompletedOnboarding` to false
    setHasCompletedOnboarding(null);
  };
  
 
   return (
     <AuthContext.Provider value={{ 
       user, 
       userData, 
       userType, 
       hasCompletedOnboarding, 
       signUp, 
       signIn, 
       signOutUser, 
       isAdmin, // ✅ Provide `isAdmin` to all components
       isEmailVerified, 
       setIsEmailVerified, 
       saveBrandQuestionnaire, 
       saveTestCampaign, 
       loading 
     }}>
       {children}
     </AuthContext.Provider>
   );
 }
 
 export function useAuth() {
   return useContext(AuthContext);
 }
 