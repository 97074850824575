

import React, { useState } from "react";
import { useAuth } from "../../contexts/authContext";

const AdminSignup = () => {
  const { signUp } = useAuth();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    userType: "admin", // Hardcoded to "admin"
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signUp(formData);
      alert("Admin account created. Please verify your email.");
    } catch (error) {
      alert("Error signing up: " + error.message);
    }
  };

  return (
    <div>
      <h2>Admin Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="firstName" placeholder="First Name" onChange={handleChange} required />
        <input type="text" name="lastName" placeholder="Last Name" onChange={handleChange} required />
        <input type="email" name="email" placeholder="Email" onChange={handleChange} required />
        <input type="password" name="password" placeholder="Password" onChange={handleChange} required />
        <button type="submit">Create Admin Account</button>
      </form>
    </div>
  );
};

export default AdminSignup;
