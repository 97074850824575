import React, { useEffect, useState } from "react";
import { 
  db  } from '../../firebase'; // Ensure db is your Firestore instance
import { fetchAllUsers, promoteToAdmin, updateUserType, deleteUser, getCollectionTotals } from "../admin/adminService";

import { useAuth } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { 
  doc, 
  getDoc,
 
 } from 'firebase/firestore';
import '../../cssFiles/admindash.css';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [totals, setTotals] = useState({});
  const { isAdmin, user, loading } = useAuth(); // Get logged-in user info
  const navigate = useNavigate();

  // ✅ Prevent Non-Admins from Accessing This Page
  useEffect(() => {
    if (loading) return; // ✅ Ensure loading is complete
    if (!isAdmin) return; // ✅ Prevents fetching users if not an admin
    console.log("User:", user);
  console.log("User Type:", user?.userType);
  console.log("isAdmin:", isAdmin);
    if (!user) {
      navigate("/dashboardb"); // 🚨 Redirect only if user is not logged in
    } else if (user && isAdmin === false) {
      console.warn("🚨 User is NOT an admin, redirecting to dashboardb.");
      navigate("/dashboardb");
    }
  }, [user, isAdmin, loading, navigate]);
  
 

  useEffect(() => {


    const getUsers = async () => {
      try {
        const userList = await fetchAllUsers();
        setUsers(userList);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const getTotals = async () => {
      try {
        const totalsData = await getCollectionTotals();
        setTotals(totalsData);
      } catch (error) {
        console.error("Error fetching totals:", error);
      }
    };
    const fetchLoginHistory = async () => {
      if (!user) return;
    
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);
    
      if (userSnap.exists()) {
        console.log("📌 User Login History:", userSnap.data().loginHistory);
      } else {
        console.error("🚨 No login history found.");
      }
    };
    
    fetchLoginHistory ();
    getUsers();
    getTotals();
  }, [isAdmin, user, loading]);

  // ✅ Handle Role Change
  const handleUserTypeChange = async (userId, newType) => {
    const result = await updateUserType(userId, newType);
    if (result.success) {
      setUsers(users.map(user => user.id === userId ? { ...user, userType: newType } : user));
    } else {
      alert("Failed to update user role.");
    }
  };

  // ✅ Promote User to Admin
  const handlePromoteToAdmin = async (userId) => {
    if (window.confirm("Promote this user to admin?")) {
      const result = await promoteToAdmin(userId);
      if (result.success) {
        setUsers(users.map(user => user.id === userId ? { ...user, userType: "admin" } : user));
        alert("User has been promoted to Admin!");
      } else {
        alert("Failed to promote user");
      }
    }
  };

  // ✅ Delete User
  const handleDelete = async (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      const result = await deleteUser(userId);
      if (result.success) {
        setUsers(users.filter(user => user.id !== userId));
      }
    }
  };

  // ✅ Show Loading Until Admin Status is Determined
  if (loading) return <p>Loading...</p>;

  // ✅ Show Access Denied if User is Not an Admin
  if (!isAdmin) {
    return <p style={{ color: "red", textAlign: "center", fontSize: "18px" }}>🚨 Access Denied: You are not an admin.</p>;
  }

  return (

    <div className="admin-dashboard">

      <h2>Admin Dashboard</h2>
      <h3>Totals</h3>
    <div className="dashboard-totals"> 
        <p>Total Users: {totals.totalUsers || 0}</p>
        <p>Total Brands: {totals.totalBrands || 0}</p>
        <p>Total Campaigns: {totals.totalCampaigns || 0}</p>
      </div>

      <table className="admin-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Email</th>
            <th>User Type</th>
            <th>Last Login</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.email}</td>

              <td>
                <select value={user.userType} onChange={(e) => handleUserTypeChange(user.id, e.target.value)}>
                  <option value="admin">Admin</option>
                  <option value="brand">Brand</option>
                  <option value="creator">Creator</option>
                </select>
              </td>
              <td>{user.lastLogin || "N/A"}</td>

             <td className="action-buttons">
                <button onClick={() => handleDelete(user.id)}>Delete</button>
                {user.userType !== "admin" && (
                  <button onClick={() => handlePromoteToAdmin(user.id)}>Make Admin</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDashboard;
