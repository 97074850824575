import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from "./contexts/authContext";

// Import Components
import Unauthorized from "./components/Unauthorized"; // ✅ Create a new NotFound component
import Navbar from './components/Navbar';

// Import Authentication
import SignHome from './components/auth/SignHome';
import SigninForm from './components/auth/SigninForm'; // Import other components as needed
import ResetPassword from './components/auth/ResetPassword';
import RequestPwrd from './components/auth/RequestPwrd';
import VerifyEmailMsg from './components/auth/VerifyEmailMsg';
import VerifyEmailHandler from './components/auth/VerifyEmailHandler';
import EmailisVerifiedMsg from './components/auth/EmailisVerifiedMsg';

// Import Admin 
import AdminDashboard from './components/admin/AdminDashboard';
import AdminSignup from './components/admin/AdminSignup';

// Import Signup Brand process
import SignUpB from './components/brands/SignUpB';
import BrandsQ from './components/brands/BrandsQ';
import BrandAccount from './components/admin/BrandAccount';
import Dashboardb from './components/brands/Dashboardb';

// Import Signup Creator process
import SignUpC from './components/creators/SignUpC';


// Test Campaigns process

import TestCampaign from './components/brands/campaignsTest/testCampaign';
import TestCampaignList from './components/brands/campaignsTest/testCampaignList';
import TestCampaignDetail from './components/brands/campaignsTest/testCampaignDetail';
import TestCampaignInfo from './components/brands/campaignsTest/TestCampaignInfo';
import TestCOverview from './components/brands/creditz/testcOverview';
import DashboardpanelA from "./components/brands/campaignsTest/DashboardpanelA";
import Dashboardtest from './components/brands/campaignsTest/Dashboardtest';
import StripeTest from './components/brands/campaignsTest/StripeTest';

import Campaign from './components/brands/Campaign';
import StartCampaign from './components/brands/forms/startCampaign';


 // Import CREDITZ
 import DashboardpanelC from "./components/brands/creditz/dashboardPanelC";
 import CredPrice from './components/brands/creditz/CredPrice';
 import Credsdesc from './components/brands/creditz/Credsdesc';
 import StripeCreditz from './components/brands/creditz/StripeCreditz';

 

// Import Full Service

import FSOverview from './components/brands/campaignsFull/fsOverview';
import DashboardpanelF from "./components/brands/campaignsFull/dashboardPanelF";
 import StripeFS from './components/brands/campaignsFull/StripeFS';




 const App = () => {

 
  const { user, userType } = useAuth(); // ✅ Get authentication data
  console.log("useAuth Output:", { user, userType });
    return (

      <Router>
     
        <div className="App">
          <Navbar />
          <Routes>

          {/* Redirect "/" to "/neuhome" */}
        <Route path="/" element={<Navigate to="/neuhome" replace />} />


             {/* Sign up, Sign In, Reset Password */}

             <Route path="/neuhome" element={<SignHome />} />  {/* Set SignHome as the default route */}
             <Route path="/login" element={<SigninForm/>} />
            <Route path="/brandsignup" element={<SignUpB/>} />
            <Route path="/creatorsignup" element={<SignUpC/>} />
            <Route path="/passrequest" element={<RequestPwrd />} />
            <Route path="/resetpass" element={<ResetPassword />} />
            <Route path="/brandsignup/emailmsg" element={<VerifyEmailMsg />} />
            <Route path="/emailmsg" element={<VerifyEmailMsg />} />
            <Route path="/VerifyEmailHandler" element={<VerifyEmailHandler/>} />
            <Route path="/accountverified" element={<EmailisVerifiedMsg/>} />
            <Route path="/brandaccount" element={<BrandAccount/>} />
               

      {/* Group A Routes with SidePanelA */}
        <Route element={<DashboardpanelA />}>
          <Route path="/testcampaigninfo" element={<TestCampaignInfo />} />
          <Route path="/testcampaign" element={<TestCampaign/>} />   
          <Route path="/testpricing" element={<StripeTest/>} />
         
        </Route>

        {/* FULL SERVICE Routes with SidePanelF */}
        <Route element={<DashboardpanelF />}>
        <Route path="/fsoverview" element={<FSOverview/>} />
        <Route path="/fspricing" element={<StripeFS/>} />

        </Route>


          {/* CREDITZ Routes with SidePanelC */}
          <Route element={<DashboardpanelC />}>
          <Route path="/crdtzdesc" element={<Credsdesc/>} />
          <Route path="/crdtzoverview" element={<TestCOverview/>} />
          <Route path="/crdtzpricing" element={<CredPrice/>} />
          
          <Route path="/crdtzpay" element={<StripeCreditz/>} />
          </Route>

          
            <Route path="/campaignslist" element={<TestCampaignList/>} />
        <Route path="/campaignslist/:campaignId" element={<TestCampaignDetail />} /> {/* ✅ Details Route */}




       {/* Brand Pages */}    
       <Route path="/onboardbrands" element={<BrandsQ/>} />
            <Route path="/Dashboardb" element={<Dashboardb/>} />
            <Route path="/campaign" element={<Campaign/>} />
            <Route path="/startcampaign" element={<StartCampaign/>} />
            <Route path="/Dashboardtest" element={<Dashboardtest/>} />

  
           



            {/* Define other routes as necessary */}
            <Route path="AdminDashboard" element={<AdminDashboard/>} />
            <Route path="AdminSignup" element={<AdminSignup/>} />

        {/* ✅ Unauthorized Page for Restricted Access */}
        <Route path="/unauthorized" element={<Unauthorized />} />

           
          </Routes>
        </div>
      </Router>

     
    );
}

export default App;
