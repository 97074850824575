// This code is for Requesting to Reset Your Password and an email will send

//📌 Key Features of This Code
// Users enter their email and submit the form
// Firebase sends a password reset email to the user
// Handles success and error messages
// Clears success messages after 5 seconds
// Handles different Firebase authentication errors

import { useState, useEffect } from "react";
import { auth } from '../../firebase';
import { sendPasswordResetEmail } from "firebase/auth";
import '../../cssFiles/index.css';

const RequestPwrd = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => setSuccessMessage(''), 5000);
            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    const actionCodeSettings = {
        url: 'http://localhost:3000/accountverified?mode=resetPassword', // ✅ Ensure this points to your reset password page
        handleCodeInApp: true,
    };

    const sendResetEmail = async () => {
        try {
            await sendPasswordResetEmail(auth, email, actionCodeSettings);
            setSuccessMessage("Password reset email sent! Please check your inbox.");
            setEmail('');
        } catch (error) {
            handleFirebaseErrors(error.code, error.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        if (!email.trim()) {
            setError("Please enter a valid email address.");
            return;
        }

        sendResetEmail();
    };

    const handleFirebaseErrors = (errorCode, errorMessage) => {
        switch (errorCode) {
            case 'auth/invalid-email':
                setError("Invalid email address. Please enter a valid email.");
                break;
            case 'auth/user-not-found':
                setError("No user found with this email. Please check your email or sign up.");
                break;
            case 'auth/too-many-requests':
                setError("Too many password reset attempts. Please try again later.");
                break;
            case 'auth/network-request-failed':
                setError("Network error. Please check your internet connection and try again.");
                break;
            default:
                setError("An error occurred. Please try again.");
                console.error("Firebase Error:", errorMessage);
        }
    };

    return (
        <div className="form-style">
            <form onSubmit={handleSubmit}>
                <h2>Request Password</h2>
                <p>Enter your email address and we'll send you a link to reset your password.</p>

                {error && <div style={{ color: 'red' }}>{error}</div>}
                {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}

                <label> Email Address</label>
                <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    autoComplete="email" 
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <button type="submit" disabled={!email.trim()}>
                    Send Password Reset Link
                </button>

                <p>Having trouble? Contact us at <span className="email-style">signup@neufluence.com</span>.</p>
            </form>
        </div>
    );
};

export default RequestPwrd;
