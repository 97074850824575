//Allow users to click on a campaign to see full details.
// Get campaign ID from URL (/campaigns/:campaignId).
// Fetches the campaign details from Firestore using campaignId from the URL.
// Allows users to edit the campaign details (Name, Budget, Dates, Objective).
//Saves edits back to Firestore using updateDoc().
// Includes a "Save Changes" button that updates Firestore.
// Handles errors and ensures smooth user experience.

import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../../../contexts/authContext"; 
import '../../../cssFiles/testdetails.css';


const TestCampaignDetail = () => {
    const { campaignId } = useParams();  // ✅ Extract campaignId from URL
    const { user } = useAuth();
    const [campaign, setCampaign] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        if (user?.uid) {
            fetchCampaignDetails(user.uid, campaignId);
        }
    }, [user, campaignId]);

    const fetchCampaignDetails = async (userId, id) => {
        if (!userId || !id) return;

        try {
            const campaignRef = doc(db, "users", userId, "testcampaign", campaignId); // ✅ FIXED
            const docSnap = await getDoc(campaignRef);
    
            if (docSnap.exists()) {
                const campaignData = docSnap.data();
                setCampaign(campaignData);
                setFormValues({
                    ...campaignData,
                    startDate: campaignData.startDate ? new Date(campaignData.startDate).toISOString().split("T")[0] : "",
                    endDate: campaignData.endDate ? new Date(campaignData.endDate).toISOString().split("T")[0] : "",
                });
            } else {
                console.error("🚨 Campaign not found");
            }
        } catch (error) {
            console.error("🚨 Error fetching campaign:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const handleSaveChanges = async () => {
        if (!user?.uid || !campaignId) return;

        try {
            const campaignRef = doc(db, `users/${user.uid}/testcampaign/${campaignId}`);

            // ✅ Convert dates to ISO format before saving
            const updatedValues = {
                ...formValues,
                startDate: formValues.startDate ? new Date(formValues.startDate).toISOString() : null,
                    endDate: formValues.endDate ? new Date(formValues.endDate).toISOString() : null
                };
        
            await updateDoc(campaignRef, updatedValues); // ✅ Save updates to Firestore
            setCampaign(updatedValues);
            setEditing(false);
            alert("✅ Campaign updated successfully!");
        } catch (error) {
            console.error("🚨 Error updating campaign:", error);
        }
    };

    if (loading) return <p>Loading campaign...</p>;
    if (!campaign) return <p>🚨 Campaign not found.</p>;

    return (

        <div >
       
           

            {editing ? (
                <div className="campaign-edit">
               <h2>Edit Your Campaign Details</h2>
               <br>
               </br>
                    <label>Campaign Name:</label>
                    <input type="text" name="campaignName" value={formValues.campaignName  || ""} onChange={handleInputChange} />

                    <label>Total Campaign Budget:</label>
                    <input type="number" name="campaignBudget" value={formValues.campaignBudget} onChange={handleInputChange} />

                    <label>Start Date:</label>
                    <input type="date" name="postingStart" value={formValues.postingStart} onChange={handleInputChange} />

                    <label>End Date:</label>
                    <input type="date" name="postingEnd" value={formValues.postingEnd} onChange={handleInputChange} />

                    <label>Campaign Description:</label>
                    <textarea name="campaignDesc" value={formValues.campaignDesc} onChange={handleInputChange} />

                    <label># of Posts Required:</label>
                    <input type="number" name="postsRequired" value={formValues.postsRequired} onChange={handleInputChange} />

                    <label># of Creators:</label>
                    <input type="number" name="creatorsNum" value={formValues.creatorsNum} onChange={handleInputChange} />

                    <label>Date Created:</label>
                    <input type="date" name="createdAt" value={formValues.createdAt} onChange={handleInputChange} />

                    <label>Status:</label>
                    <select name="status" value={formValues.status} onChange={handleInputChange}>
                        <option value="Draft">Draft</option>
                        <option value="Active">Active</option>
                        <option value="Completed">Completed</option>
                    </select>
          
                    <button onClick={handleSaveChanges}>Save Changes</button>
                    <button onClick={() => setEditing(false)}>Cancel</button>
                </div>
            ) : (
              
                <div className="campaign-summary">
                <h2>{campaign.campaignName}</h2>
            
  {/* Campaign Description with Label on Top */}
  <div className="campaign-summary-desc">
        <label>Campaign Description:</label>
        <p>{campaign.campaignDesc || "N/A"}</p>
    </div>
            
                {/* Two-Column Grid for Other Data */}
                <div className="campaign-summary-grid">
                    <p><strong>Total Campaign Budget:<br></br></strong> ${campaign.campaignBudget || "N/A"}</p>
                    <p><strong># of Posts Required:<br></br> </strong> {campaign.postsRequired || "N/A"}</p>
            
                    <p><strong># of Creators:<br></br></strong> {campaign.creatorsNum || "N/A"}</p>
                    <p><strong>Date Created:<br></br></strong> {campaign.createdAt ? new Date(campaign.createdAt).toLocaleDateString() : "N/A"}</p>
            
                    <p><strong>Start Date:<br></br></strong> {campaign.postingStart ? new Date(campaign.postingStart).toLocaleDateString() : "N/A"}</p>
                    <p><strong>End Date:<br></br> </strong> {campaign.postingEnd ? new Date(campaign.postingEnd).toLocaleDateString() : "N/A"}</p>
            
                    <p><strong>Status:</strong> <span className={`status-label ${campaign.status?.toLowerCase()}`}>{campaign.status}</span></p>
                </div>
                <div className="campaign-summary-buttons">
                {/* Buttons */}
                <button onClick={() => setEditing(true)}>Edit Campaign</button>
                <Link to="/campaignslist">← Back to Campaign List</Link>
                </div>
            </div>
            
            )}
       
        </div>
    );
};

export default TestCampaignDetail;
