

// SidePanel.js

import { Link } from 'react-router-dom';
import '../../../cssFiles/index.css'; // Assuming your CSS file is named Dashboard.css


const SidePanel = () => {

   
    return (
        <div className="sidebar">
            <Link to="/testcampaign">Start A Test Campaign</Link>
            <Link to="/testcampaigninfo">Test Campaign Overview</Link>
            <Link to="/testpricing">Pay for Test Campaign</Link>


         
           
            
        </div>
    );
};

export default SidePanel;