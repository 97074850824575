        
import { useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import Modal from "../Modal"; // ✅ Ensure you have a modal component
import '../../cssFiles/dashboardb.css';

import { useAuth } from "../../contexts/authContext";

const Dashboardb = () => {
  const navigate = useNavigate();
  const { user, hasCompletedOnboarding, loading } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showTestCampaignModal, setShowTestCampaignModal] = useState(false);

  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (!loading && user && hasCompletedOnboarding === false) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [user, hasCompletedOnboarding, loading]);
  

  const handleCompleteOnboarding = () => {
    setShowModal(false);
    navigate("/onboardbrands"); // ✅ Redirect to onboarding page
  };

  // Open Test Campaign Modal
  const handleOpenTestCampaignModal = () => {
    setShowTestCampaignModal(true);
  };

  // Close Test Campaign Modal
  const handleCloseTestCampaignModal = () => {
    setShowTestCampaignModal(false);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-box">
        
          <h2>Welcome To Your Dashboard</h2>
          <p style={{ fontWeight: '500'}}>Select one of our 
            budget-friendly options below to get started with your creator campaigns.
            <br></br>
           
            </p>
       
        <br />
        <div className="button-group">
          <div className="button-row">
          <button className="dashboard-button test" onClick={handleOpenTestCampaignModal}>
            Test Campaigns <br />
            <span style={{ fontSize: '0.5em' }}>(Test your campaign idea.)</span>

            </button>
            <button className="dashboard-button fullService" onClick={() => handleNavigation('/fsoverview')}>
              Full Service Plans
            </button>
            <button className="dashboard-button  creditz" onClick={() => handleNavigation('/crdtzdesc')}>
            Buy CREDITZ <br />
            <span style={{ fontSize: '0.5em' }}>(Pay-As-You-Go)</span>
</button>

          </div>
          <button style={{ background: '#d211d2'}}
            className="submit-button" 
            onClick={() => handleNavigation('/campaignslist')}
          >
            View My Campaigns
          </button>
          <p style={{ fontSize: '15px', color: '#fc8320', fontWeight: '700', }}>  
            Have more questions? <a href="https://calendly.com/team-661/neufluence-full-service-discovery-call-15-minu-clone" target="_blank" rel="noopener noreferrer">
            Click here </a> to schedule a call.</p> 
            <div>
        <p style={{ fontSize: '16px', color: '#2c3e50', fontWeight: '500', marginTop: 1 }}> 
            You can also email us at signup@neufluence.com</p>

            </div>
        </div>
      </div>

       {/* Onboarding Modal */}

      <Modal className="modal-content" show={showModal} onClose={handleCompleteOnboarding}>
        <p>Last step! <br></br> <br></br> We just need you to complete your profile before proceeding to the dashboard.</p>
        <button className="modal-close" onClick={handleCompleteOnboarding}>Complete Profile</button>
      </Modal>

       {/* Test Campaign Modal */}
      <Modal className="modal-content" show={showTestCampaignModal} onClose={handleCloseTestCampaignModal}>
        <p>Choose an option for your Test Campaign:</p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '15px' }}>
          <button 
            className="close-button" 
            onClick={() => handleNavigation('/testcampaign')}
          >
            Get a review of your test campaign.
          </button>
          <button 
            className="close1-button" 
            onClick={() => handleNavigation('/testpricing')}
          >
            Pay for your test campaign.
          </button>
        </div>
        {/* ✅ Close button added to return to dashboard */}
        <button className="modal-close" onClick={handleCloseTestCampaignModal}>
          Close
        </button>
      </Modal>
    </div>
  );
};

export default Dashboardb;