import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const StartCampaign = () => {
  const iframeRef = useRef(null);
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const handleIframeLoad = () => {
      try {
        const iframe = iframeRef.current;
        if (iframe && iframe.contentWindow) {
          const doc = iframe.contentWindow.document;

          // Check if the thank you message is visible
          if (doc.body.innerText.includes("Thank you")) {
            setSubmitted(true);
          }
        }
      } catch (error) {
        // Cross-origin restriction might prevent accessing iframe content
        console.warn("Cannot access iframe content due to cross-origin restrictions.");
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("load", handleIframeLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleIframeLoad);
      }
    };
  }, []);

  useEffect(() => {
    if (submitted) {
      navigate("/dashboard");
    }
  }, [submitted, navigate]);

  return (
    <div className="dashboard-container">
   
<div style={{ width: 700, height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
    <br></br>
<div className="dashboard-box">
<h2>Basic Campaign Details</h2>
<p>The form will appear in a few moments.</p>
<iframe
  className="airtable-embed"
  src="https://airtable.com/embed/appsbDQrOX8G32okH/pagZG8DiFSM3Bv9nL/form"
  frameBorder="0"
  title="Start Campaign"
  onMouseWheel=""
  width="100%"
  height="600"
  style={{ background: "transparent", border: "1px solid #fc8320" }} // ✅ Fixed style prop
></iframe>
</div>
</div>
</div>
  );
};

export default StartCampaign;
