// This code is for verifying  a users Email after sign up
// The user is sent to this page after clicking Signup
import {  useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import { sendEmailVerification } from 'firebase/auth';
import PreventBackNavigation from "../../utils/PreventBackNavigation";

const VerifyEmailMsg = () => {

  PreventBackNavigation(); // ✅ Prevents back navigation

  const navigate = useNavigate();
  const { user, isEmailVerified } = useAuth(); // ✅ Get user and verification state
  const [message, setMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [, setEmailVerified] = useState(() => {
    return JSON.parse(localStorage.getItem("emailVerified")) || false;
  });
  


// ✅ Use `useRef` to persist BroadcastChannel
const verificationChannel = useRef(null);


  useEffect(() => {

    // ✅ Initialize `BroadcastChannel` only once
 verificationChannel.current = new BroadcastChannel("email_verification_channel");


    
    // ✅ Check if the email is already verified in global state
    if (isEmailVerified) {
      setEmailVerified(true);
    } else {
    
      // ✅ Check localStorage for verification status
      const storedVerification = JSON.parse(localStorage.getItem("emailVerified"));
      if (storedVerification) {
        setEmailVerified(true);
      }
    }

    // ✅ Listen for email verification messages from other tabs
    verificationChannel.current.onmessage = (event) => {
      if (event.data.emailVerified) {
        setEmailVerified(true);
        localStorage.setItem("emailVerified", JSON.stringify(true));

        // ✅ Redirect to login after a short delay
        window.location.href = "https://www.neufluence.com"; 
      }
    };

    return () => {
      verificationChannel.current.close();
    };
  }, [navigate, isEmailVerified]);


  const handleResendEmail = async () => {
    if (user) {
      try {
        await sendEmailVerification(user);
        setMessage("Verification email resent. Please check your inbox.");
        setIsButtonDisabled(true);
        // Re-enable the button after some time
        setTimeout(() => setIsButtonDisabled(false), 60000); // 60 seconds
      } catch (error) {
        console.error("Error resending verification email: ", error);
        setMessage("Error resending email. Please try again later.");
      }
    } else {
      setMessage ("No user found. Please log in and try again.");
    }
    
  };

  return (
    <div className="form-style">
      <h2 style={{ color: '#fc8320' }}> Confirm your Email </h2>
     
      <p>To activate your account, we need to reconfirm your email.  Please check your email account (and spam!) for our verification email.</p>
      <p style={{ color: '#fc8320' }}> If you didn’t receive an email, wait a few moments and click the Resend Email button.</p>

     {message && <p style={{ color: 'green' }}className="message">{message}</p>}
  
      <button type="button" 
      onClick={handleResendEmail}
       disabled={isButtonDisabled || !user}>
        Resend Email</button>
      <br></br>


      <p>Still having trouble? Contact us at <span className="email-style">signup@neufluence.com</span>.</p>
    </div>
  );
}

export default VerifyEmailMsg
