

// src/layouts/DashboardpanelA.js for test campaign


import SidePanel from './sidepanelC';
import { Outlet } from "react-router-dom";
import '../../../cssFiles/index.css';


const DashboardpanelC = () => {
    return (
        <div className="dashboard-layout">
          <div className="sidebar">
            <SidePanel />
          </div>
       
            <Outlet />
      
        </div>
      );
    };

export default DashboardpanelC;
