// Allow users to view their created test campaigns in a list format.

// Query Firestore for campaigns inside users/{userId}/testcampaign.
//Sort campaigns by createdAt in descending order.
// Display campaign name, status, and date.
//Provide a clickable link to view campaign details.

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../firebase';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { useAuth } from "../../../contexts/authContext"; 
import { Link } from 'react-router-dom';
import '../../../cssFiles/dashboardb.css';

const TestCampaignList = () => {
    const { user } = useAuth();
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortField, setSortField] = useState('createdAt'); // Default sort by createdAt
    const [sortOrder, setSortOrder] = useState('desc'); // Default sort order descending
    const navigate = useNavigate();

    const handleNavigation = (path) => {
      navigate(path);
    };

    useEffect(() => {
        if (!user?.uid) return;

        // ✅ Firestore query based on selected sorting options
        const testCampaignRef = collection(db, `users/${user.uid}/testcampaign`);
        const q = query(testCampaignRef, orderBy(sortField, sortOrder));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const campaignList = snapshot.docs.map(doc => ({
                id: doc.id,   // ✅ Firestore document ID (campaignId)
                campaignType: "Test", // ✅ Set Campaign Type to "Test" for this collection
                ...doc.data()
               
            }));
            setCampaigns(campaignList);
            setLoading(false);
        }, (error) => {
            console.error('🚨 Error fetching campaigns:', error);
            setLoading(false);
        });

        return () => unsubscribe(); // ✅ Cleanup listener on unmount
    }, [user, sortField, sortOrder]); // ✅ Refetch when sorting changes

    return (
        
        <div className="account1-container">
   
        <div className="account2-box">
          <div className="createhead">
            <h2>Active Campaigns Overview</h2>   </div>

            {/* ✅ Sorting Controls */}

      
                <label>Sort By: </label>
                <select value={sortField} onChange={(e) => setSortField(e.target.value)}>
                    <option value="campaignName">Campaign Name</option>
                    <option value="createdAt">Created At</option>
                </select>



            {loading ? (
          <p>Loading campaigns...</p>
        ) : campaigns.length === 0 ? (
          <p>No campaigns found.</p>
        ) : (
          <table className="campaign-table">
            <thead>
              <tr>
                <th>Campaign Name</th>
                <th>Campaign Type</th> {/* ✅ New Column */}
                <th>Status</th>
                <th>Created Date</th>
                <th>Action</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {campaigns.map((campaign) => (
                <tr key={campaign.id}>
                  <td>{campaign.campaignName}</td>
                  <td>{campaign.campaignType}</td> {/* ✅ Display Campaign Type */}
                  <td>
                    <span className={`status-label ${campaign.status.toLowerCase()}`}>
                      {campaign.status}
                    </span>
                  </td>
                  <td>
                    {campaign.createdAt
                      ? new Date(campaign.createdAt.seconds * 1000).toLocaleDateString()
                      : "No Date"}
                  </td>
                  <td>
                    <Link to={`/campaignslist/${campaign.id}`} className="campaign-link">
                      Edit Overview
                    </Link>
                  </td>
                  <td>
                    <Link to={`/unauthorized`} className="campaign-link">
                      Edit Campaign
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          
          </table>
          
        )}
          
      </div>
      <button 
            className="submit-button" 
            style={{fontSize: 18}}
            onClick={() => handleNavigation('/dashboardb')}
          >
            Back to Main Dashboard
          </button>
    </div>
  );
};

export default TestCampaignList;