


import * as Yup from 'yup';

/**
 * ✅ Test Campaign Validation Schema
 * This schema defines validation rules for the campaign form using Yup.
 * Ensures all required fields are filled correctly before submission.
 */
export const  TestCampaignSchema = Yup.object().shape({
  campaignName: Yup.string().required("Please enter the campaign name"),
  campaignDesc: Yup.string().required("Please provide a campaign description"),
  creatorsNum: Yup.number().min(1, "At least 1 creator is required").required("This field is required"),
  postsRequired: Yup.number().min(1, "At least 1 post is required").required("This field is required"),
  postingStart: Yup.date().required("Please select a start date"),
  postingEnd: Yup.date().required("Please select an end date").min(Yup.ref("postingStart"), "End date must be after start date"),
  feeRange: Yup.string().required("Please provide a fee range"),
  campaignBudget: Yup.number().min(1, "Budget must be at least $1").required("This field is required"),
  campaignPreference: Yup.string().required("Please select an option"),
});