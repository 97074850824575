
import { useNavigate } from 'react-router-dom';
import '../../../cssFiles/dashboardb.css';





const Dashboardtest = () => {
  const navigate = useNavigate();


  const handleNavigation = (path) => {
    navigate(path);
  };

 

 

  return (
    <div className="dashboard-container">
      <div className="dashboard-box">
        <div className="createhead">
          <h2>Start Here With Your Test Campaign</h2>
          <p>This screen will guide you in building your test campaign.  <br></br>
          Click on the buttons in order to add details for your test campaign idea.</p>
        </div>

        <a 
  href="/testcampaigninfo" 
  target="_blank" 
  rel="noopener noreferrer"
  style={{ color: '#fc8320', textAlign: 'center', marginBottom: 15, display: 'block', textDecoration: 'underline', fontWeight: 'bold' }}
>
  Click here to learn what tools and services are included in your test campaign.
</a>

     
     
        <div className="button-group">
          <div className="button-row">
            <button className="dashboardtest-button" onClick={() => handleNavigation('/startcampaign')}>
              1. <br></br>Basic Campaign Details
            </button>
            <button className="dashboardtest-button" onClick={() => handleNavigation('/searchCreators')}>
            2. <br></br>Campaign Messaging and Content
            </button>
          </div>
          <div className="button-row">
            <button className="dashboardtest-button" onClick={() => handleNavigation('/Sample')}>
            3. <br></br>Target Audience & Creator Criteria
            </button>
            <button className="dashboardtest-button" onClick={() => handleNavigation('/payRateCalculator')}>
            4. <br></br>Success Metrics
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboardtest;